<template>
  <v-dialog
    v-model="isShowModal"
    persistent
    max-width="800px">
    <v-card>
      <material-card
        :title="$t('evoucher.assignVoucher')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-radio-group
              v-model="isPrepay"
              row
            >
              <v-radio
                :value="true"
                :label="$t('createVoucher.prepaid')"
              />
              <v-radio
                :value="false"
                :label="$t('createVoucher.unpaid')"
              />
            </v-radio-group>
            <v-layout
              wrap
              layout>
              <v-flex
                xs12
                sm6
                md6>
                <v-autocomplete
                  :rules="[ruleRequiredValue]"
                  v-model="workpieceId"
                  :items="workpieceList"
                  :clearable="true"
                  :label="$t('workpiece.list')"
                  type="text"
                  class="required"
                  @change="voucherIssuanceChange()"
                >
                  <template v-slot:item="data">
                    <span>{{ data.item.text }} <span class="grey--text font-small">[{{ data.item.code }}]</span></span>
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6>
                <v-text-field
                  :rules="[ruleRequiredValue]"
                  v-model="remainQuantityVoucher"
                  :label="$t('evoucher.remainQuantity')"
                  disabled
                />
              </v-flex>
              <v-flex
                v-if="createVoucherType == CreateVoucherTypeSingle"
                xs12
                sm6
                md6
              >
                <div style="max-height: 300px; overflow-y: auto;">
                  <div
                    v-for="(eVoucher, index) in voucherList"
                    :key="eVoucher + index"
                    style="display: flex;"
                  >
                    <v-text-field
                      :rules="[ruleRequiredValue, ruleValidCode]"
                      v-model="eVoucher.serial"
                      :label="$t('evoucher.voucherSerial')"
                      type="text"
                      class="required"
                    />
                    <v-icon
                      v-show="voucherList.length > 1"
                      style="cursor: pointer;"
                      color="red"
                      @click="removeEVoucher(index)"
                    >mdi-close</v-icon
                    >
                  </div>
                </div>
                <v-btn
                  style="margin: 0 !important; padding: 0 !important;"
                  depressed
                  small
                  @click="addNewVoucher()"
                >
                  <div style="display: flex; align-items: center;">
                    <v-icon>mdi-plus</v-icon>
                    <span style="text-transform: none; font-weight: normal;">{{
                      $t("evoucher.addNewVoucher")
                    }}</span>
                  </div>
                </v-btn>
              </v-flex>
              <v-flex
                v-if="createVoucherType == CreateVoucherTypeList"
                xs12
                sm6
                md6
              >
                <v-text-field
                  :rules="[ruleRequiredValue, ruleMustGreaterThanZero, ruleMustLessThanOrEqual]"
                  v-model="quantity"
                  :label="$t('evoucher.voucherQuantity')"
                  class="required"
                  type="number"
                />
              </v-flex>
              <v-flex
                v-if="createVoucherType == CreateVoucherTypeList"
                xs12
                sm6
                md6
              >
                <v-text-field
                  :rules="[ruleRequiredValue, ruleCodeLengthMustGreaterThanOrEqual]"
                  v-model="lengthCode"
                  :label="$t('evoucher.lengthCode')"
                  class="required"
                  type="number"
                />
              </v-flex>
              <v-flex
                xs12
                sm12
                md12
              >
                <v-checkbox
                  v-model="isSelectIssuance"
                  :label="$t('createVoucher.selectPrefixSuffix')"
                  class="customer-check"
                />
              </v-flex>
              <v-flex
                v-if="isSelectIssuance"
                xs12
                sm6
                md6
              >
                <v-autocomplete
                  :rules="[ruleRequiredValue]"
                  v-model="issuanceId"
                  :items="issuanceList"
                  :clearable="true"
                  :label="$t('createVoucher.selectPrefixSuffix')"
                  type="text"
                  class="required"
                  @change="onSelecedIssuance"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    {{ concatSuffixPrefix(data.item) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    {{ concatSuffixPrefix(data.item) }}
                  </template>
                </v-autocomplete>
              </v-flex>
              <v-flex
                v-if="createVoucherType == CreateVoucherTypeList && !isSelectIssuance"
                xs12
                sm3
                md3
              >
                <v-text-field
                  :rules="[ruleRequiredValue, ruleValidCode]"
                  v-model="startChar"
                  :label="$t('evoucher.characterStarts')"
                  class="required"
                />
              </v-flex>
              <v-flex
                v-if="createVoucherType == CreateVoucherTypeList && !isSelectIssuance"
                xs12
                sm3
                md3
              >
                <v-text-field
                  :rules="[ruleRequiredValue, ruleValidCode]"
                  v-model="endChar"
                  :label="$t('evoucher.characterEnd')"
                  class="required"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-menu
                  ref="menuEndDate"
                  v-model="menuIssueExpiredDate"
                  :close-on-content-click="false"
                  :disabled="isSelectIssuance"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  lazy
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :rules="[ruleRequiredValue]"
                      v-model="distributeEndDateFormatted"
                      :label="$t('createVoucher.issuanceDeadlineToCustomers')"
                      :clearable="!isSelectIssuance"
                      :disabled="isSelectIssuance"
                      persistent-hint
                      class="required"
                      @blur="date = parseDate(distributeEndDateFormatted)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="distributeEndDate"
                    :max="maxVoucherExpiredDate"
                    :min="startValidDate"
                    no-title
                    @input="menuIssueExpiredDate = false"
                  />
                </v-menu>
              </v-flex>
            </v-layout>
            <!-- Release deadline to customer -->
            <v-layout
              wrap
              layout
            >
              <!-- Expiry date of customers -->
              <v-flex
                v-if="forConasi"
                xs12
                sm6
                md6
              >
                <v-select
                  v-model="selectedExpirationType"
                  :items="expiredDateSelectItem"
                  :placeholder="$t('user.privilege')"
                >
                  <template
                    slot="selection"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                  <template
                    slot="item"
                    slot-scope="data">
                    {{ $t(data.item.text) }}
                  </template>
                </v-select>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-if="selectedExpirationType === ExpirationType.DURATION"
                  :rules="[ruleRequiredValue, ruleMustGreaterThanZero]"
                  v-model="usageDuration"
                  :label="$t('evoucher.duration') + ' ' + $t('createVoucher.afterDistributedCustomers') + ` (` + $t('evoucher.issuance.day') + `)`"
                  :name="$t('evoucher.duration') + ` (` + $t('evoucher.issuance.day') + `)`"
                  type="number"
                  class="required"
                />
                <v-menu
                  v-if="selectedExpirationType === ExpirationType.EXPIRY_DATE"
                  ref="menuEndDate"
                  v-model="menuValidEndDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  lazy
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="usageEndDateFormatted"
                      :label="$t('createVoucher.toDate')"
                      :clearable="true"
                      persistent-hint
                      @blur="date = parseDate(usageEndDateFormatted)"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="usageEndDate"
                    :min="startValidDate"
                    no-title
                    @input="menuValidEndDate = false"
                  />
                </v-menu>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="primary darken-1"
          style="position: absolute;"
          @click="onAddAndDistribute"
        >{{ $t('createVoucher.addAndRelease') }}
        </v-btn>
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onShowModalConfirmCreateVoucher"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <assignVoucherModal
      ref="assignVoucherModal"
    />
    <!-- Confirm assign voucher modal -->
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="onConfirmRedirectToAssignVoucher"
    />
    <!-- Confirm create voucher modal -->
    <confirm-modal
      ref="confirmCreateVoucherModal"
      :title="confirmCreateModalTitle"
      @onConfirm="onConfirm"
    />
    <confirm-modal
      ref="confirmCreateVoucherWithPrefixSuffixExists"
      :title="confirmCreateModalTitle"
      @onConfirm="onConfirmCreateVoucherWithPrefixSuffixExists"
    />
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS, DEFAULT_NUMBER_OF_DISTRIBUTING_EXPIRED_DAYS, FOR_CONASI_TARGET } from 'utils/constants'
import CreateVoucherType from 'enum/createVoucherType'
import VoucherIssuanceStatusType from 'enum/voucherIssuanceStatusType'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import ToastType from 'enum/toastType'
import ExpirationType from 'enum/expirationType'
import dateUtils from 'utils/dateUtils'
import moment from 'moment'
import AssignVoucherModal from './AssignVoucherModal'
export default {
  components: {
    AssignVoucherModal
  },
  data: () => ({
    valid: true,
    isShowModal: false,
    quantity: null,
    lengthCode: 5,
    startChar: null,
    endChar: null,
    workpieceList: [],
    workpieceId: null,
    createVoucherType: null,
    CreateVoucherTypeList: CreateVoucherType.LIST,
    CreateVoucherTypeSingle: CreateVoucherType.SINGLE,
    voucherList: [{
      serial: null
    }],
    remainQuantityVoucher: 0,
    // Start issue expired date to customer
    distributeEndDate: moment().add(DEFAULT_NUMBER_OF_DISTRIBUTING_EXPIRED_DAYS, 'days').format('YYYY-MM-DD'),
    distributeEndDateFormatted: moment().add(DEFAULT_NUMBER_OF_DISTRIBUTING_EXPIRED_DAYS, 'days').format('DD/MM/YYYY'),
    maxVoucherExpiredDate: null,
    menuIssueExpiredDate: false, // End issue expired date
    // Start issue start date to customer
    startValidDate: moment().add(1, 'days').format('YYYY-MM-DD'),
    usageDuration: DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS,
    isSelectIssuance: false,
    issuanceId: null,
    issuanceList: [],
    confirmModalTitle: '',
    voucherData: [],
    addAndDistribute: false,
    isPrepay: true,
    isLoading: false,
    confirmCreateModalTitle: '',
    isConfirmCreateIssuance: false,
    selectedExpirationType: ExpirationType.DURATION,
    ExpirationType: ExpirationType,
    menuValidEndDate: false,
    usageEndDateFormatted: moment().add(DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS, 'days').format('YYYY-MM-DD'),
    usageEndDate: moment().add(DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS, 'days').format('YYYY-MM-DD'),
    expiredDateSelectItem: [
      { value: ExpirationType.DURATION, text: 'createVoucher.availableUsingDuration' },
      { value: ExpirationType.EXPIRY_DATE, text: 'createVoucher.expiredDateOfCustomers' }
    ],
    forConasi: FOR_CONASI_TARGET
  }),
  computed: {
    ...mapGetters([
      'GET_SNACK_BAR',
      'VOUCHER_ISSUANCES_DATA',
      'REMAIN_QUANTITY_VOUCHER_IN_ISSUANCE_DATA',
      'GET_ISSUANCE_LIST_DATA'
    ])
  },
  watch: {
    /**
     * Issue expired date to agent
     */
    distributeEndDate () {
      this.distributeEndDateFormatted = this.formatDate(this.distributeEndDate)
    },
    selectedExpirationType () {
      if (this.selectedExpirationType === ExpirationType.EXPIRY_DATE) {
        this.maxVoucherExpiredDate = this.usageEndDate
        if (!this.isSelectIssuance && (this.distributeEndDate > this.usageEndDate)) {
          this.distributeEndDate = this.usageEndDate
        }
      } else {
        this.maxVoucherExpiredDate = null
      }
    },
    VOUCHER_ISSUANCES_DATA () {
      let data = this.VOUCHER_ISSUANCES_DATA
      this.workpieceList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          text: data[i].name,
          code: data[i].code,
          value: data[i].id
        }
        this.workpieceList.push(obj)
      }
    },
    REMAIN_QUANTITY_VOUCHER_IN_ISSUANCE_DATA () {
      let data = this.REMAIN_QUANTITY_VOUCHER_IN_ISSUANCE_DATA
      this.remainQuantityVoucher = data.remain_quantity
      this.quantity = this.remainQuantityVoucher > 0 ? this.remainQuantityVoucher : null
    },
    GET_ISSUANCE_LIST_DATA () {
      let data = this.GET_ISSUANCE_LIST_DATA
      this.issuanceList = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          value: data[i].id,
          prefix: data[i].prefix,
          suffix: data[i].suffix,
          distributeEndDate: data[i].distribute_end_date,
          usageDuration: data[i].usage_duration,
          usageEndDate: data[i].usage_end_date
        }
        this.issuanceList.push(obj)
      }
    },
    /**
     * Valid date
     */
    usageEndDate (val) {
      this.usageEndDateFormatted = this.formatDate(this.usageEndDate)
      if (this.selectedExpirationType === ExpirationType.EXPIRY_DATE) {
        this.maxVoucherExpiredDate = this.usageEndDate
      } else {
        this.maxVoucherExpiredDate = null
      }
      if (!this.isSelectIssuance) {
        let expectedDistributeEndDate = moment().add(DEFAULT_NUMBER_OF_DISTRIBUTING_EXPIRED_DAYS, 'days').format('YYYY-MM-DD')
        if (expectedDistributeEndDate > this.usageEndDate) {
          expectedDistributeEndDate = this.usageEndDate
        }
        this.distributeEndDate = expectedDistributeEndDate
      }
    }
  },
  methods: {
    /**
     * Show modal confirm create voucher
     */
    onShowModalConfirmCreateVoucher: function () {
      this.confirmCreateModalTitle = this.$t('evoucher.areYouSureCreateThisVoucher')
      this.$refs.confirmCreateVoucherModal.onShowModal()
    },
    /**
     * Add new voucher and distribute
     */
    onAddAndDistribute: function () {
      this.addAndDistribute = true
      this.validate()
    },
    /**
     * Confirm redirect to assign voucher
     */
    onConfirmRedirectToAssignVoucher: function () {
      this.$refs.assignVoucherModal.onShowModal(this.voucherData)
    },
    /**
     * Prefix suffix change
     */
    onSelecedIssuance: function () {
      let selectedIssuance = this.issuanceList.filter(element => {
        return element.value === this.issuanceId
      })
      if (selectedIssuance.length > 0) {
        this.startChar = selectedIssuance[0].prefix
        this.endChar = selectedIssuance[0].suffix
        if (!functionUtils.isEmptyString(selectedIssuance[0].distributeEndDate)) {
          let distributeEndDateData = moment(selectedIssuance[0].distributeEndDate)
          this.distributeEndDate = distributeEndDateData.format('YYYY-MM-DD')
          this.distributeEndDateFormatted = distributeEndDateData.format('DD/MM/YYYY')
          this.maxVoucherExpiredDate = distributeEndDateData.format('YYYY-MM-DD')
        }
      }
    },
    // Format date
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    // Parse date
    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    concatSuffixPrefix: function (suffixPrefix) {
      return functionUtils.concatSuffixPrefix(suffixPrefix)
    },
    /**
     * Check not greater than remain e voucher
     */
    ruleMustLessThanOrEqual (value) {
      return validationRules.ruleMustLessThanOrEqual(value, this.remainQuantityVoucher)
    },
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    ruleMustGreaterThanZero (value) {
      return validationRules.ruleMustGreaterThan(value, 0)
    },
    ruleCodeLengthMustGreaterThanOrEqual (value) {
      // Check length code greater 3
      return validationRules.ruleMustGreaterThanOrEqual(value, 3)
    },
    ruleValidCode (value) {
      return validationRules.ruleValidCode(value)
    },
    /**
     * E voucher issuance change
     */
    voucherIssuanceChange: function () {
      if (this.workpieceId) {
        let filter = {
          params: {
            workpieceId: this.workpieceId
          }
        }
        this.GET_REMAIN_VOUCHER_QUANTITY_IN_ISSUANCE(filter)
      } else {
        this.remainQuantityVoucher = 0
      }
      let filter = {
        params: {
          workpieceId: this.workpieceId
        }
      }
      this.GET_ISSUANCE_LIST(filter)
    },
    /**
     * Remove e voucher
     */
    removeEVoucher: function (index) {
      if (this.voucherList.length > 1) {
        this.voucherList.splice(index, 1)
      }
    },
    /**
     * GET PREFIX SUFFIX
     */
    getIssuanceList: function (workpieceId) {
      let filter = {
        params: {
          workpieceId: workpieceId
        }
      }
      this.GET_ISSUANCE_LIST(filter)
    },
    /**
     * Show modal
     */
    onShowModal: function (createType, workpieceId, issuanceId) {
      this.createVoucherType = createType
      this.isShowModal = true
      this.getEVoucherIssuanceList()
      if (workpieceId) {
        this.workpieceId = workpieceId
        this.voucherIssuanceChange()
        this.getIssuanceList(this.workpieceId)
      }
      if (issuanceId) {
        this.isSelectIssuance = true
        this.issuanceId = issuanceId
        this.onSelecedIssuance()
      }
      this.isConfirmCreateIssuance = false
      this.selectedExpirationType = 1

      this.usageEndDate = moment().add(DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS, 'days').format('YYYY-MM-DD')
      this.usageEndDateFormatted = this.formatDate(this.usageEndDate)

      this.distributeEndDate = moment().add(DEFAULT_NUMBER_OF_DISTRIBUTING_EXPIRED_DAYS, 'days').format('YYYY-MM-DD')
      this.distributeEndDateFormatted = this.formatDate(this.usageDuration)
      if (this.selectedExpirationType === ExpirationType.EXPIRY_DATE) {
        this.maxVoucherExpiredDate = this.usageEndDate
      } else {
        this.maxVoucherExpiredDate = null
      }
    },
    /**
     * Get e voucher issuance list
     */
    getEVoucherIssuanceList: function () {
      let filter = {
        params: {
          excludePromotion: true,
          excludeStatus: VoucherIssuanceStatusType.WAIT_FOR_APPROVAL
        }
      }
      this.GET_VOUCHER_ISSUANCE_LIST(filter)
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.isShowModal = false
      this.setDefault()
    },
    setDefault: function () {
      this.remainQuantityVoucher = 0
      // eslint-disable-next-line eqeqeq
      if (this.createVoucherType == this.CreateVoucherTypeList) {
        this.quantity = null
        this.usageDuration = DEFAULT_NUMBER_OF_USING_EXPIRED_DAYS
        this.lengthCode = 5
        this.startChar = null
        this.endChar = null
        this.workpieceId = null
      } else {
        this.workpieceId = null
        this.voucherList = [{
          serial: null
        }]
      }
      this.issuanceId = null
      this.isSelectIssuance = false
    },
    /**
     * Validate
     */
    validate: function () {
      if (this.$refs.form.validate()) {
        if (this.createVoucherType === this.CreateVoucherTypeList) {
          let startChar = functionUtils.removeSpaceInString(this.startChar.toLowerCase())
          let endChar = functionUtils.removeSpaceInString(this.endChar.toLowerCase())
          if (functionUtils.hasSpecialCharacter(startChar) ||
              !functionUtils.validUsername(startChar) ||
              functionUtils.hasSpecialCharacter(endChar) ||
              !functionUtils.validUsername(endChar)) {
            this.ON_SHOW_TOAST({
              'message': this.$t('evoucher.canNotHasSpecialCharacter'),
              'styleType': ToastType.ERROR
            })
            return
          }
        }
        let usageEndDate = null
        if (this.selectedExpirationType === ExpirationType.EXPIRY_DATE) {
          if (functionUtils.isEmptyString(this.usageEndDateFormatted)) {
            this.ON_SHOW_TOAST({
              'message': this.$t('createVoucher.availableUsingDuration'),
              'styleType': ToastType.ERROR
            })
            return
          } else {
            usageEndDate = dateUtils.formatSqlDate(this.usageEndDateFormatted)
          }
        }
        let distributeEndDate = dateUtils.formatSqlDate(this.distributeEndDateFormatted)
        let filter = {
          type: this.createVoucherType,
          quantity: this.quantity,
          lengthCode: this.lengthCode,
          startChar: this.startChar,
          endChar: this.endChar,
          workpieceId: this.workpieceId,
          voucherList: this.voucherList,
          distributeEndDate: distributeEndDate,
          deliveredDate: null,
          usedExpiredDate: null,
          isPrepay: this.isPrepay,
          isSelectIssuance: this.isSelectIssuance,
          isConfirmCreateIssuance: this.isConfirmCreateIssuance,
          usageDuration: this.selectedExpirationType === ExpirationType.DURATION ? this.usageDuration : null,
          usageEndDate: usageEndDate
        }
        this.isLoading = true
        this.CREATE_VOUCHER(filter)
          .then(
            function (res) {
              // Hide modal add voucher
              this.isShowModal = false
              this.$emit('createEVoucherSuccess')
              this.isLoading = false
              this.setDefault()
              if (this.addAndDistribute) {
                // Assign data and continue distribute voucher
                this.voucherData = res.data
                this.confirmModalTitle = this.$t('createVoucher.keepIssuingVouchersJustCreated')
                this.$refs.confirmModal.onShowModal()
              }
              this.isConfirmCreateIssuance = false
            }.bind(this)
          )
          .catch(
            function (error) {
              this.isLoading = false
              if (functionUtils.isNull(error.response)) {
                this.ON_SHOW_TOAST({
                  message: this.$t('login.somethingWentWrong'),
                  styleType: ToastType.ERROR
                })
              } else if (!functionUtils.isNull(error.response.data.code)) {
                let errorText = error.response.data.code
                if (errorText === 'evoucher.notiConfirmCreateVoucherWithIssuanceExists') {
                  this.confirmCreateModalTitle = this.$t('evoucher.notiConfirmCreateVoucherWithIssuanceExists',
                    { 'prefix': this.startChar, 'suffix': this.endChar })
                  this.$refs.confirmCreateVoucherWithPrefixSuffixExists.onShowModal()
                } else {
                  this.ON_SHOW_TOAST({
                    message: this.$t(`${errorText}`),
                    styleType: ToastType.ERROR
                  })
                }
              } else {
                this.ON_SHOW_TOAST({
                  message: this.$t('login.somethingWentWrong'),
                  styleType: ToastType.ERROR
                })
              }
            }.bind(this)
          )
      }
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      this.addAndDistribute = false
      this.validate()
    },
    /**
     * Add new e voucher
     */
    addNewVoucher: function () {
      this.voucherList.push({
        serial: null
      })
    },
    onConfirmCreateVoucherWithPrefixSuffixExists: function () {
      this.isSelectIssuance = true
      this.validate()
    },
    ...mapActions([
      'CREATE_VOUCHER',
      'GET_VOUCHER_ISSUANCE_LIST',
      'GET_REMAIN_VOUCHER_QUANTITY_IN_ISSUANCE',
      'ON_SHOW_TOAST',
      'GET_ISSUANCE_LIST'
    ])
  }
}
</script>

<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
.v-picker {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.container.grid-list-xl .layout .flex {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
.customer-check /deep/.v-input__slot {
  margin: 0;
}
.customer-check /deep/.v-label {
  font-size: 14px;
  font-weight: normal;
}
.customer-check /deep/.v-messages {
  display: none;
}
</style>
