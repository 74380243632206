<template>
  <v-dialog
    v-model="isShowModal"
    :max-width="MODAL_WIDTH"
    persistent>
    <v-card>
      <material-card
        :title="$t('evoucher.voucherDistribution')"
        tile
        color="green"
        full-width
      >
        <v-container grid-list-xl>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation>
            <v-radio-group
              v-if="isAgent() || !forConasi"
              v-model="isAssignForCustomer"
              row
              @change="assignForCustomerChange"
            >
              <v-radio
                v-if="!isAssignFromRequestSent"
                :value="true"
                :label="$t('evoucher.customer')"
              />
              <v-radio
                :value="false"
                :label="$t('entity.agent')"
              />
              <!-- <v-radio
                v-if="(forConasi && !isAgent()) || !forConasi"
                v-else-if="!forConasi && isSupplier()"
                :value="false"
                :label="$t('entity.agent')"
              /> -->
            </v-radio-group>
            <div v-if="voucherSelected.length > 0">
              <div v-if="isAgent()">
                <span v-if="!isExcelMode">{{
                  $t("infoVoucher.voucherInformation")
                }}</span>
                <v-layout
                  v-if="!isExcelMode"
                  wrap>
                  <v-flex
                    xs12
                    sm4
                    md4>
                    <v-text-field
                      v-model="voucherSelected.length"
                      :label="$t('evoucher.quantity')"
                      :name="$t('evoucher.quantity')"
                      disabled
                      type="text"
                    />
                  </v-flex>
                  <v-flex
                    xs12
                    sm4
                    md4>
                    <v-text-field
                      v-model="priceFormat"
                      :label="voucherValueType == VoucherValue.PRICE ? $t('infoVoucher.unitPrice') : $t('evoucher.discountPrice') + ' %'"
                      :name="voucherValueType == VoucherValue.PRICE ? $t('infoVoucher.unitPrice') : $t('evoucher.discountPrice') + ' %'"
                      disabled
                    />
                  </v-flex>
                  <!-- Total price format -->
                  <v-flex
                    xs12
                    sm4
                    md4>
                    <v-text-field
                      v-model="totalPriceFormat"
                      :label="$t('workpieceDetail.totalPrice')"
                      :name="$t('workpieceDetail.totalPrice')"
                      disabled
                    />
                  </v-flex>
                  <v-flex
                    v-if="isAgent() && !isAssignForCustomer"
                    xs12
                    sm4
                    md4
                  >
                    <v-autocomplete
                      :rules="[ruleRequiredValue]"
                      v-model="agent.id"
                      :items="agent.data"
                      :clearable="true"
                      :label="$t('entity.agent')"
                      class="required"
                    />
                  </v-flex>
                  <v-flex
                    v-if="isAgent() && isAssignForCustomer"
                    xs12
                    sm4
                    md4
                  >
                    <v-text-field
                      :rules="[ruleRequiredValue, ruleValidPhoneOrEmail]"
                      v-model="customerEmail"
                      :label="forConasi ? $t('common.email') : $t('common.emailOrPhone')"
                      :name="forConasi ? $t('common.email') : $t('common.emailOrPhone')"
                      type="text"
                      class="required"
                    />
                  </v-flex>
                </v-layout>
                <div v-if="!isAssignForCustomer && !isExcelMode">
                  <span>{{
                    $t("infoVoucher.discountInformationFromParent")
                  }}</span>
                  <v-layout wrap>
                    <v-flex
                      v-if="discountType == VoucherTransactionValue.PERCENT"
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        v-model="infoDiscountFromParent.discount"
                        :label="'% ' + $t('generalConfiguration.discount')"
                        :name="'% ' + $t('generalConfiguration.discount')"
                        type="number"
                        disabled
                      />
                    </v-flex>
                    <v-flex
                      v-else
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        v-model="infoDiscountFromParent.priceDiscount"
                        :label="$t('assignVoucher.discountedFaceValue')"
                        :name="$t('assignVoucher.discountedFaceValue')"
                        type="number"
                        disabled
                      />
                    </v-flex>
                    <v-flex
                      v-if="voucherValueType == VoucherValue.PRICE"
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        v-model="infoDiscountFromParent.totalPriceDiscount"
                        :label="
                          $t('assignVoucher.totalAmountOfDiscount') + ' (' + voucherSelected.length + ' ' + (voucherSelected.length > 1 ? $t('assignVoucher.vouchers') : $t('assignVoucher.voucher')) + ')'
                        "
                        :name="
                          $t('assignVoucher.totalAmountOfDiscount') + ' (' + voucherSelected.length + ' ' + (voucherSelected.length > 1 ? $t('assignVoucher.vouchers') : $t('assignVoucher.voucher')) + ')'
                        "
                        disabled
                      />
                    </v-flex>
                  </v-layout>
                </div>
                <span v-if="!isAssignForCustomer">{{
                  $t("infoVoucher.discountInfoForChildren")
                }}</span>
                <v-radio-group
                  v-if="!isAssignForCustomer"
                  v-model="isBonusWithDiscount"
                  row
                  @change="onChangeDiscountV2"
                >
                  <v-radio
                    :value="true"
                    :label="'% ' + $t('evoucher.discount')" />
                  <v-radio
                    v-if="voucherValueType == VoucherValue.PRICE"
                    :value="false"
                    :label="$t('assignVoucher.discountedFaceValue')"
                  />
                </v-radio-group>
                <v-layout wrap>
                  <!-- Value for all voucher -->
                  <v-flex
                    v-if="(!isAssignForCustomer || isSupplier()) && isBonusWithDiscount"
                    xs12
                    sm4
                    md4
                  >
                    <v-text-field
                      :rules="[
                        ruleMustLessThanOneHundred,
                        ruleMustNotLessThanZero
                      ]"
                      v-model="discount"
                      :label="'% ' + $t('generalConfiguration.discount')"
                      :name="'% ' + $t('generalConfiguration.discount')"
                      :min="0"
                      :max="100"
                      :disabled="!isBonusWithDiscount"
                      type="number"
                      @input="onChangeDiscountV2"
                    />
                  </v-flex>
                  <v-flex
                    v-if="(!isAssignForCustomer || isSupplier()) && voucherValueType == VoucherValue.PRICE && !isBonusWithDiscount"
                    xs12
                    sm4
                    md4
                  >
                    <v-text-field
                      :rules="[ruleNotGreaterThanUnitPrice]"
                      v-model="priceDiscountFormat"
                      :label="$t('assignVoucher.discountedFaceValue') + ' (1 voucher)'"
                      :name="$t('assignVoucher.discountedFaceValue') + ' (1 voucher)'"
                      :disabled="isBonusWithDiscount"
                      @input="onChangeDiscountV2"
                    />
                  </v-flex>
                  <v-flex
                    v-if="(!isAssignForCustomer || isSupplier()) && voucherValueType == VoucherValue.PRICE"
                    xs12
                    sm4
                    md4
                  >
                    <v-text-field
                      v-model="totalPriceDiscount"
                      :label="$t('assignVoucher.totalAmountOfDiscount') + ' (' + voucherSelected.length + ' ' + (voucherSelected.length > 1 ? $t('assignVoucher.vouchers') : $t('assignVoucher.voucher')) + ')'"
                      :name="$t('assignVoucher.totalAmountOfDiscount') + ' (' + voucherSelected.length + ' ' + (voucherSelected.length > 1 ? $t('assignVoucher.vouchers') : $t('assignVoucher.voucher')) + ')'"
                      disabled
                    />
                  </v-flex>
                </v-layout>
              </div>
              <div v-else>
                <!-- Assign to DLC1 form -->
                <div v-if="forConasi || (!forConasi && !isAssignForCustomer)">
                  <span>{{ $t("infoVoucher.voucherInformation") }}</span>
                  <v-layout wrap>
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        v-model="voucherSelected.length"
                        :label="$t('evoucher.quantity')"
                        :name="$t('evoucher.quantity')"
                        disabled
                        type="text"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        v-model="priceFormat"
                        :label="voucherValueType == VoucherValue.PRICE ? $t('infoVoucher.unitPrice') : $t('evoucher.discountPrice') + ' %'"
                        :name="voucherValueType == VoucherValue.PRICE ? $t('infoVoucher.unitPrice') : $t('evoucher.discountPrice') + ' %'"
                        disabled
                      />
                    </v-flex>
                    <!-- Total price format -->
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        v-model="totalPriceFormat"
                        :label="$t('workpieceDetail.totalPrice')"
                        :name="$t('workpieceDetail.totalPrice')"
                        disabled
                      />
                    </v-flex>
                    <v-flex
                      v-if="isSupplier()"
                      xs12
                      sm4
                      md4>
                      <v-autocomplete
                        :rules="[ruleRequiredValue]"
                        v-model="distributer.id"
                        :items="distributer.data"
                        :clearable="true"
                        :label="$t('entity.agent')"
                        class="required"
                      />
                    </v-flex>
                  </v-layout>
                  <span>{{ $t("infoVoucher.discountInfoForChildren") }}</span>
                  <v-radio-group
                    v-model="isBonusWithDiscount"
                    row
                    @change="onChangeDiscountV2">
                    <v-radio
                      :value="true"
                      :label="'% ' + $t('evoucher.discount')" />
                    <v-radio
                      v-if="voucherValueType == VoucherValue.PRICE"
                      :value="false"
                      :label="$t('assignVoucher.discountedFaceValue')"
                    />
                  </v-radio-group>
                  <v-layout>
                    <v-flex
                      v-if="isAgent() && !isAssignForCustomer"
                      xs12
                      sm4
                      md4
                    >
                      <v-autocomplete
                        :rules="[ruleRequiredValue]"
                        v-model="agent.id"
                        :items="agent.data"
                        :clearable="true"
                        :label="$t('entity.agent')"
                        class="required"
                      />
                    </v-flex>
                    <v-flex
                      v-if="isAgent() && isAssignForCustomer"
                      xs12
                      sm4
                      md4
                    >
                      <v-text-field
                        :rules="[ruleRequiredValue, ruleValidPhoneOrEmail]"
                        v-model="customerEmail"
                        :label="forConasi ? $t('common.email') : $t('common.emailOrPhone')"
                        :name="forConasi ? $t('common.email') : $t('common.emailOrPhone')"
                        type="text"
                        class="required"
                      />
                    </v-flex>
                    <v-flex
                      v-if="(!isAssignForCustomer || isSupplier()) && isBonusWithDiscount"
                      xs12
                      sm4
                      md4
                    >
                      <v-text-field
                        :rules="[
                          ruleMustLessThanOneHundred,
                          ruleMustNotLessThanZero
                        ]"
                        v-model="discount"
                        :label="'% ' + $t('generalConfiguration.discount')"
                        :name="'% ' + $t('generalConfiguration.discount')"
                        :min="0"
                        :max="100"
                        :disabled="!isBonusWithDiscount"
                        type="number"
                        @input="onChangeDiscountV2"
                      />
                    </v-flex>
                    <v-flex
                      v-if="(!isAssignForCustomer || isSupplier()) && voucherValueType == VoucherValue.PRICE && !isBonusWithDiscount"
                      xs12
                      sm4
                      md4
                    >
                      <v-text-field
                        :rules="[ruleNotGreaterThanUnitPrice]"
                        v-model="priceDiscountFormat"
                        :label="$t('assignVoucher.discountedFaceValue') + ' (1 voucher)'"
                        :name="$t('assignVoucher.discountedFaceValue') + ' (1 voucher)'"
                        :disabled="isBonusWithDiscount"
                        @input="onChangeDiscountV2"
                      />
                    </v-flex>
                    <v-flex
                      v-if="(!isAssignForCustomer || isSupplier()) && voucherValueType == VoucherValue.PRICE"
                      xs12
                      sm4
                      md4
                    >
                      <v-text-field
                        v-model="totalPriceDiscount"
                        :label="$t('assignVoucher.totalAmountOfDiscount') + ' (' + voucherSelected.length + ' ' + (voucherSelected.length > 1 ? $t('assignVoucher.vouchers') : $t('assignVoucher.voucher')) + ')'"
                        :name="$t('assignVoucher.totalAmountOfDiscount') + ' (' + voucherSelected.length + ' ' + (voucherSelected.length > 1 ? $t('assignVoucher.vouchers') : $t('assignVoucher.voucher')) + ')'"
                        disabled
                      />
                    </v-flex>
                  </v-layout>
                </div>
                <div v-else>
                  <span v-if="!isExcelMode">{{
                    $t("infoVoucher.voucherInformation")
                  }}</span>
                  <v-layout
                    v-if="!isExcelMode"
                    wrap>
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        v-model="voucherSelected.length"
                        :label="$t('evoucher.quantity')"
                        :name="$t('evoucher.quantity')"
                        disabled
                        type="text"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        v-model="priceFormat"
                        :label="voucherValueType == VoucherValue.PRICE ? $t('infoVoucher.unitPrice') : $t('evoucher.discountPrice') + ' %'"
                        :name="voucherValueType == VoucherValue.PRICE ? $t('infoVoucher.unitPrice') : $t('evoucher.discountPrice') + ' %'"
                        disabled
                      />
                    </v-flex>
                    <!-- Total price format -->
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        v-model="totalPriceFormat"
                        :label="$t('workpieceDetail.totalPrice')"
                        :name="$t('workpieceDetail.totalPrice')"
                        disabled
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm4
                      md4
                    >
                      <v-text-field
                        :rules="[ruleRequiredValue, ruleValidPhoneOrEmail]"
                        v-model="customerEmail"
                        :label="forConasi ? $t('common.email') : $t('common.emailOrPhone')"
                        :name="forConasi ? $t('common.email') : $t('common.emailOrPhone')"
                        type="text"
                        class="required"
                      />
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </div>
            <div v-else>
              <div v-if="isAgent()">
                <span v-if="!isExcelMode">{{
                  $t("infoVoucher.voucherInformation")
                }}</span>
                <v-layout
                  v-if="!isExcelMode"
                  row
                  wrap>
                  <v-flex
                    xs12
                    sm4
                    md4>
                    <v-autocomplete
                      :rules="[ruleRequiredValue, validateDiscount]"
                      v-model="workpieceList.id"
                      :items="workpieceList.data"
                      :clearable="true"
                      :label="forConasi ? $t('assignVoucher.selectTheWorkPieceCode') : $t('assignVoucher.selectVoucher')"
                      :disabled="isAssignFromRequestSent"
                      class="required"
                      @change="onWorkpieceChange"
                    >
                      <template v-slot:item="data">
                        <span>{{ data.item.text }} <span class="grey--text font-small">[{{ data.item.code }}]</span></span>
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <!-- Prefix and suffix -->
                  <v-flex
                    v-if="forConasi"
                    xs12
                    sm4
                    md4>
                    <v-autocomplete
                      :rules="[ruleRequiredValue]"
                      v-model="issuanceId"
                      :items="issuanceList"
                      :clearable="true"
                      :label="$t('assignVoucher.chooseIssuance')"
                      type="text"
                      class="required"
                      @change="onChangeIssuance"
                    >
                      <template
                        slot="selection"
                        slot-scope="data">
                        {{ concatSuffixPrefix(data.item) }}
                      </template>
                      <template
                        slot="item"
                        slot-scope="data">
                        {{ concatSuffixPrefix(data.item) }}
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <!-- Agent -->
                  <v-flex
                    v-if="isAgent() && !isAssignForCustomer"
                    xs12
                    sm4
                    md4
                  >
                    <v-autocomplete
                      :rules="[ruleRequiredValue]"
                      v-model="agent.id"
                      :items="agent.data"
                      :clearable="true"
                      :label="$t('assignVoucher.selectAgent')"
                      :disabled="isAssignFromRequestSent"
                      class="required"
                    />
                  </v-flex>
                  <!-- Email -->
                  <v-flex
                    v-if="isAgent() && isAssignForCustomer"
                    xs12
                    sm4
                    md4
                  >
                    <v-text-field
                      :rules="[ruleRequiredValue, ruleValidPhoneOrEmail]"
                      v-model="customerEmail"
                      :label="forConasi ? $t('common.email') : $t('common.emailOrPhone')"
                      :name="forConasi ? $t('common.email') : $t('common.emailOrPhone')"
                      type="text"
                      class="required"
                    />
                  </v-flex>
                  <!-- Voucher available quantity -->
                  <v-flex
                    xs12
                    sm4
                    md4>
                    <v-text-field
                      v-model="quantityVoucherAvailableOfEntity"
                      :label="$t('evoucher.numberOfVouchersAvailable')"
                      :name="$t('evoucher.numberOfVouchersAvailable')"
                      disabled
                      type="text"
                    />
                  </v-flex>
                  <!-- Voucher quantity -->
                  <v-flex
                    xs12
                    sm4
                    md4>
                    <v-text-field
                      :rules="[
                        ruleRequiredValue,
                        ruleMustGreaterThanZero,
                        ruleMustLessThanAvailableEVoucher,
                      ]"
                      v-model="quantityAssign"
                      :label="$t('evoucher.quantityToAssign')"
                      :name="$t('evoucher.quantityToAssign')"
                      :max="quantityVoucherAvailableOfEntity"
                      :min="0"
                      :disabled="isAssignFromRequestSent"
                      type="number"
                      class="required"
                      @input="onQuantityAssignChange"
                    />
                  </v-flex>
                  <!-- Supplier, Distributer, Agent -->
                  <!-- <v-flex
                    v-if="isSupplier()"
                    xs12
                    sm4
                    md4>
                    <v-autocomplete
                      :rules="[ruleRequiredValue]"
                      v-model="distributer.id"
                      :items="distributer.data"
                      :clearable="true"
                      :label="$t('entity.agent')"
                      type="text"
                      class="required"
                    />
                  </v-flex> -->
                  <v-flex
                    xs12
                    sm4
                    md4>
                    <v-text-field
                      v-model="priceFormat"
                      :label="voucherValueType == VoucherValue.PRICE ? $t('infoVoucher.unitPrice') : $t('evoucher.discountPrice') + ' %'"
                      :name="voucherValueType == VoucherValue.PRICE ? $t('infoVoucher.unitPrice') : $t('evoucher.discountPrice') + ' %'"
                      disabled
                    />
                  </v-flex>
                  <!-- Total price format -->
                  <v-flex
                    xs12
                    sm4
                    md4>
                    <v-text-field
                      v-model="totalPriceFormat"
                      :label="$t('workpieceDetail.totalPrice')"
                      :name="$t('workpieceDetail.totalPrice')"
                      disabled
                    />
                  </v-flex>
                  <v-flex
                    v-if="issuanceId && !isAssignForCustomer && forConasi"
                    xs12
                    sm4
                    md4>
                    <v-menu
                      ref="menuEndDate"
                      v-model="menuIssueExpiredDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                      lazy
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :rules="[ruleRequiredValue]"
                          v-model="voucherExpiredDateFormatted"
                          :label="$t('createVoucher.issuanceDeadlineToCustomers')"
                          :clearable="true"
                          persistent-hint
                          class="required"
                          disabled
                          @blur="
                            date = parseDate(voucherExpiredDateFormatted)
                          "
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="voucherExpiredDate"
                        :max="maxVoucherExpiredDate"
                        no-title
                        @input="menuIssueExpiredDate = false"
                      />
                    </v-menu>
                  </v-flex>
                </v-layout>
                <span v-if="!isAssignForCustomer && !isExcelMode">{{
                  $t("infoVoucher.discountInformationFromParent")
                }}</span>
                <v-layout
                  v-if="!isAssignForCustomer && !isExcelMode"
                  wrap>
                  <v-flex
                    v-if="discountType == VoucherTransactionValue.PERCENT"
                    xs12
                    sm4
                    md4>
                    <v-text-field
                      v-model="infoDiscountFromParent.discount"
                      :label="'% ' + $t('generalConfiguration.discount')"
                      :name="'% ' + $t('generalConfiguration.discount')"
                      type="number"
                      disabled
                    />
                  </v-flex>
                  <v-flex
                    v-else
                    xs12
                    sm4
                    md4>
                    <v-text-field
                      v-model="infoDiscountFromParent.priceDiscount"
                      :label="$t('assignVoucher.discountedFaceValue')"
                      :name="$t('assignVoucher.discountedFaceValue')"
                      type="number"
                      disabled
                    />
                  </v-flex>
                  <v-flex
                    v-if="voucherValueType == VoucherValue.PRICE"
                    xs12
                    sm4
                    md4>
                    <v-text-field
                      v-model="infoDiscountFromParent.totalPriceDiscount"
                      :label="
                        $t('assignVoucher.totalAmountOfDiscount') + ' (' + (quantityAssign ? quantityAssign : 0) + ' ' + (quantityAssign > 1 ? $t('assignVoucher.vouchers') : $t('assignVoucher.voucher')) + ')'
                      "
                      :name="
                        $t('assignVoucher.totalAmountOfDiscount') + ' (' + (quantityAssign ? quantityAssign : 0) + ' ' + (quantityAssign > 1 ? $t('assignVoucher.vouchers') : $t('assignVoucher.voucher')) + ')'
                      "
                      disabled
                    />
                  </v-flex>
                </v-layout>
                <span v-if="!isAssignForCustomer">{{
                  $t("infoVoucher.discountInfoForChildren")
                }}</span>
                <v-radio-group
                  v-if="!isAssignForCustomer"
                  v-model="isBonusWithDiscount"
                  row
                  @change="onChangeDiscountV2"
                >
                  <v-radio
                    :value="true"
                    :label="'% ' + $t('evoucher.discount')" />
                  <v-radio
                    v-if="voucherValueType == VoucherValue.PRICE"
                    :value="false"
                    :label="$t('assignVoucher.discountedFaceValue')"
                  />
                </v-radio-group>
                <v-layout wrap>
                  <v-flex
                    v-if="(!isAssignForCustomer || isSupplier()) && isBonusWithDiscount"
                    xs12
                    sm4
                    md4
                  >
                    <v-text-field
                      :rules="[
                        ruleMustLessThanOneHundred,
                        ruleMustNotLessThanZero
                      ]"
                      v-model="discount"
                      :label="'% ' + $t('generalConfiguration.discount')"
                      :name="'% ' + $t('generalConfiguration.discount')"
                      :min="0"
                      :max="100"
                      :disabled="!isBonusWithDiscount"
                      type="number"
                      @input="onChangeDiscountV2"
                    />
                  </v-flex>
                  <v-flex
                    v-if="(!isAssignForCustomer || isSupplier()) && voucherValueType == VoucherValue.PRICE && !isBonusWithDiscount"
                    xs12
                    sm4
                    md4
                  >
                    <v-text-field
                      :rules="[ruleNotGreaterThanUnitPrice]"
                      v-model="priceDiscountFormat"
                      :label="$t('assignVoucher.discountedFaceValue') + ' (1 voucher)'"
                      :name="$t('assignVoucher.discountedFaceValue') + ' (1 voucher)'"
                      :disabled="isBonusWithDiscount"
                      @input="onChangeDiscountV2"
                    />
                  </v-flex>
                  <v-flex
                    v-if="(!isAssignForCustomer || isSupplier()) && voucherValueType == VoucherValue.PRICE"
                    xs12
                    sm4
                    md4
                  >
                    <v-text-field
                      v-model="totalPriceDiscount"
                      :label="$t('assignVoucher.totalAmountOfDiscount') + ' (' + (quantityAssign ? quantityAssign : 0) + ' ' + (quantityAssign > 1 ? $t('assignVoucher.vouchers') : $t('assignVoucher.voucher')) + ')'"
                      :name="$t('assignVoucher.totalAmountOfDiscount') + ' (' + (quantityAssign ? quantityAssign : 0) + ' ' + (quantityAssign > 1 ? $t('assignVoucher.vouchers') : $t('assignVoucher.voucher')) + ')'"
                      disabled
                    />
                  </v-flex>
                </v-layout>
                <!-- TODO: need allow Agent chosse email template when deliverig voucher to customer? -->
                <!-- <v-layout
                  row
                  wrap>
                  <v-flex
                    v-if="!forConasi && (emailTemplateList.length > 0)"
                    xs12
                    sm6
                    md6>
                    <v-autocomplete
                      v-model="emailTemplateId"
                      :items="emailTemplateList"
                      :clearable="true"
                      :label="$t('templateEmail.chooseTemplate')"
                    />
                  </v-flex>
                </v-layout> -->
              </div>
              <div v-else>
                <!-- Transfer to DLC1 form -->
                <div v-if="forConasi || (!forConasi && !isAssignForCustomer)">
                  <span>{{ $t("infoVoucher.voucherInformation") }}</span>
                  <v-layout
                    row
                    wrap>
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-autocomplete
                        :rules="[ruleRequiredValue, validateDiscount]"
                        v-model="workpieceList.id"
                        :items="workpieceList.data"
                        :clearable="true"
                        :label="forConasi ? $t('assignVoucher.selectTheWorkPieceCode') : $t('assignVoucher.selectVoucher')"
                        :disabled="isAssignFromRequestSent"
                        class="required"
                        @change="onWorkpieceChange"
                      >
                        <template v-slot:item="data">
                          <span>{{ data.item.text }} <span class="grey--text font-small">[{{ data.item.code }}]</span></span>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <!-- Prefix and suffix -->
                    <v-flex
                      v-if="forConasi"
                      xs12
                      sm4
                      md4>
                      <v-autocomplete
                        :rules="[ruleRequiredValue]"
                        v-model="issuanceId"
                        :items="issuanceList"
                        :clearable="true"
                        :label="$t('assignVoucher.chooseIssuance')"
                        type="text"
                        class="required"
                        @change="onChangeIssuance"
                      >
                        <template
                          slot="selection"
                          slot-scope="data">
                          {{ concatSuffixPrefix(data.item) }}
                        </template>
                        <template
                          slot="item"
                          slot-scope="data">
                          {{ concatSuffixPrefix(data.item) }}
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <!-- Agent -->
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-autocomplete
                        :rules="[ruleRequiredValue]"
                        v-model="distributer.id"
                        :items="distributer.data"
                        :clearable="true"
                        :label="$t('assignVoucher.selectAgent')"
                        :disabled="isAssignFromRequestSent"
                        type="text"
                        class="required"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        v-model="quantityVoucherAvailableOfEntity"
                        :label="$t('evoucher.numberOfVouchersAvailable')"
                        :name="$t('evoucher.numberOfVouchersAvailable')"
                        disabled
                        type="text"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        :rules="[
                          ruleRequiredValue,
                          ruleMustGreaterThanZero,
                          ruleMustLessThanAvailableEVoucher,
                        ]"
                        v-model="quantityAssign"
                        :label="$t('evoucher.quantityToAssign')"
                        :name="$t('evoucher.quantityToAssign')"
                        :max="quantityVoucherAvailableOfEntity"
                        :min="0"
                        :disabled="isAssignFromRequestSent"
                        type="number"
                        class="required"
                        @input="onQuantityAssignChange"
                      />
                    </v-flex>
                    <!-- Button create more voucher -->
                    <v-flex
                      v-if="forConasi && hasAction('create_voucher')"
                      xs12
                      sm4
                      md4>
                      <v-btn
                        style="text-transform: none; width: 100%;"
                        small
                        color="success darken-1"
                        @click="onCreateMoreVoucher($event)"
                      >{{ $t('createVoucher.createMoreVouchers') }}
                      </v-btn>
                    </v-flex>
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        v-model="priceFormat"
                        :label="voucherValueType == VoucherValue.PRICE ? $t('infoVoucher.unitPrice') : $t('evoucher.discountPrice') + ' %'"
                        :name="voucherValueType == VoucherValue.PRICE ? $t('infoVoucher.unitPrice') : $t('evoucher.discountPrice') + ' %'"
                        disabled
                      />
                    </v-flex>
                    <!-- Total price format -->
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        v-model="totalPriceFormat"
                        :label="$t('workpieceDetail.totalPrice')"
                        :name="$t('workpieceDetail.totalPrice')"
                        disabled
                      />
                    </v-flex>
                    <v-flex
                      v-if="issuanceId && forConasi"
                      xs12
                      sm4
                      md4>
                      <v-menu
                        ref="menuEndDate"
                        v-model="menuIssueExpiredDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        lazy
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :rules="[ruleRequiredValue]"
                            v-model="voucherExpiredDateFormatted"
                            :label="$t('createVoucher.issuanceDeadlineToCustomers')"
                            :clearable="true"
                            persistent-hint
                            class="required"
                            disabled
                            @blur="
                              date = parseDate(voucherExpiredDateFormatted)
                            "
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="voucherExpiredDate"
                          no-title
                          @input="menuIssueExpiredDate = false"
                        />
                      </v-menu>
                    </v-flex>
                  </v-layout>
                  <span>{{ $t("infoVoucher.discountInfoForChildren") }}</span>
                  <v-radio-group
                    v-model="isBonusWithDiscount"
                    row
                    @change="onChangeDiscountV2">
                    <v-radio
                      :value="true"
                      :label="'% ' + $t('evoucher.discount')" />
                    <v-radio
                      v-if="voucherValueType == VoucherValue.PRICE"
                      :value="false"
                      :label="$t('assignVoucher.discountedFaceValue')"
                    />
                  </v-radio-group>
                  <v-layout
                    row
                    wrap>
                    <v-flex
                      v-if="(!isAssignForCustomer || isSupplier()) && isBonusWithDiscount"
                      xs12
                      sm4
                      md4
                    >
                      <v-text-field
                        :rules="[
                          ruleMustLessThanOneHundred,
                          ruleMustNotLessThanZero
                        ]"
                        v-model="discount"
                        :label="'% ' + $t('generalConfiguration.discount')"
                        :name="'% ' + $t('generalConfiguration.discount')"
                        :min="0"
                        :max="100"
                        :disabled="!isBonusWithDiscount"
                        type="number"
                        @input="onChangeDiscountV2"
                      />
                    </v-flex>
                    <v-flex
                      v-if="(!isAssignForCustomer || isSupplier()) && voucherValueType == VoucherValue.PRICE && !isBonusWithDiscount"
                      xs12
                      sm4
                      md4
                    >
                      <v-text-field
                        :rules="[ruleNotGreaterThanUnitPrice]"
                        v-model="priceDiscountFormat"
                        :label="$t($t('assignVoucher.discountedFaceValue')) + ' (1 voucher)'"
                        :name="$t($t('assignVoucher.discountedFaceValue')) + ' (1 voucher)'"
                        :disabled="isBonusWithDiscount"
                        @input="onChangeDiscountV2"
                      />
                    </v-flex>
                    <v-flex
                      v-if="(!isAssignForCustomer || isSupplier()) && voucherValueType == VoucherValue.PRICE"
                      xs12
                      sm4
                      md4
                    >
                      <v-text-field
                        v-model="totalPriceDiscount"
                        :label="$t('assignVoucher.totalAmountOfDiscount') + ' (' + (quantityAssign ? quantityAssign : 0) + ' ' + (quantityAssign > 1 ? $t('assignVoucher.vouchers') : $t('assignVoucher.voucher')) + ')'"
                        :name="$t('assignVoucher.totalAmountOfDiscount') + ' (' + (quantityAssign ? quantityAssign : 0) + ' ' + (quantityAssign > 1 ? $t('assignVoucher.vouchers') : $t('assignVoucher.voucher')) + ')'"
                        disabled
                      />
                    </v-flex>
                  </v-layout>
                </div>
                <div v-else>
                  <span v-if="!isExcelMode">{{
                    $t("infoVoucher.voucherInformation")
                  }}</span>
                  <v-layout
                    v-if="!isExcelMode"
                    row
                    wrap>
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-autocomplete
                        :rules="[ruleRequiredValue, validateDiscount]"
                        v-model="workpieceList.id"
                        :items="workpieceList.data"
                        :clearable="true"
                        :label="forConasi ? $t('assignVoucher.selectTheWorkPieceCode') : $t('assignVoucher.selectVoucher')"
                        :disabled="isAssignFromRequestSent"
                        class="required"
                        @change="onWorkpieceChange"
                      >
                        <template v-slot:item="data">
                          <span>{{ data.item.text }} <span class="grey--text font-small">[{{ data.item.code }}]</span></span>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <!-- Prefix and suffix -->
                    <v-flex
                      v-if="forConasi"
                      xs12
                      sm4
                      md4>
                      <v-autocomplete
                        :rules="[ruleRequiredValue]"
                        v-model="issuanceId"
                        :items="issuanceList"
                        :clearable="true"
                        :label="$t('assignVoucher.chooseIssuance')"
                        type="text"
                        class="required"
                        @change="onChangeIssuance"
                      >
                        <template
                          slot="selection"
                          slot-scope="data">
                          {{ concatSuffixPrefix(data.item) }}
                        </template>
                        <template
                          slot="item"
                          slot-scope="data">
                          {{ concatSuffixPrefix(data.item) }}
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex
                      xs12
                      sm4
                      md4
                    >
                      <v-text-field
                        :rules="[ruleRequiredValue, ruleValidPhoneOrEmail]"
                        v-model="customerEmail"
                        :label="forConasi ? $t('common.email') : $t('common.emailOrPhone')"
                        :name="forConasi ? $t('common.email') : $t('common.emailOrPhone')"
                        type="text"
                        class="required"
                      />
                    </v-flex>
                    <!-- Voucher available quantity -->
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        v-model="quantityVoucherAvailableOfEntity"
                        :label="$t('evoucher.numberOfVouchersAvailable')"
                        :name="$t('evoucher.numberOfVouchersAvailable')"
                        disabled
                        type="text"
                      />
                    </v-flex>
                    <!-- Voucher quantity -->
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        :rules="[
                          ruleRequiredValue,
                          ruleMustGreaterThanZero,
                          ruleMustLessThanAvailableEVoucher,
                        ]"
                        v-model="quantityAssign"
                        :label="$t('evoucher.quantityToAssign')"
                        :name="$t('evoucher.quantityToAssign')"
                        :max="quantityVoucherAvailableOfEntity"
                        :min="0"
                        :disabled="isAssignFromRequestSent"
                        type="number"
                        class="required"
                        @input="onQuantityAssignChange"
                      />
                    </v-flex>
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        v-model="priceFormat"
                        :label="voucherValueType == VoucherValue.PRICE ? $t('infoVoucher.unitPrice') : $t('evoucher.discountPrice') + ' %'"
                        :name="voucherValueType == VoucherValue.PRICE ? $t('infoVoucher.unitPrice') : $t('evoucher.discountPrice') + ' %'"
                        disabled
                      />
                    </v-flex>
                    <!-- Total price format -->
                    <v-flex
                      xs12
                      sm4
                      md4>
                      <v-text-field
                        v-model="totalPriceFormat"
                        :label="$t('workpieceDetail.totalPrice')"
                        :name="$t('workpieceDetail.totalPrice')"
                        disabled
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout
                    row
                    wrap>
                    <v-flex
                      v-if="!forConasi && (emailTemplateList.length > 0)"
                      xs12
                      sm6
                      md6>
                      <v-autocomplete
                        v-model="emailTemplateId"
                        :items="emailTemplateList"
                        :clearable="true"
                        :label="$t('templateEmail.chooseTemplate')"
                      />
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </div>
            <div v-if="isAssignForCustomer">
              <v-text-field
                :rules="[ruleLengthMustLessThan]"
                v-model="deliveryNote"
                :label="$t('assignVoucher.deliveryNote')"
                :name="$t('assignVoucher.deliveryNote')"
                prepend-icon="mdi-tooltip-edit"
                type="text"
              />
            </div>
          </v-form>
        </v-container>
      </material-card>
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="error darken-1"
          @click="onCloseModal"
        >{{ $t("common.close") }}
        </v-btn>
        <!-- <v-btn
          small
          color="warning darken-1"
          @click="onPreviewPrintVoucher"
        >{{ $t('evoucher.issuance.previewPrint') }}
        </v-btn> -->
        <v-btn
          small
          color="info darken-1"
          @click="onPreviewVoucherInfo"
        >{{ $t('evoucher.issuance.previewVoucher') }}
        </v-btn>
        <v-btn
          :loading="isLoading"
          small
          color="success darken-1"
          @click="onConfirm"
        >{{ $t("common.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    />
    <confirm-modal
      ref="confirmModal"
      :title="confirmModalTitle"
      @onConfirm="confirmPrintEVoucher()"
    />
    <confirmAssignVoucherModal
      ref="confirmAssignVoucherModal"
      @onConfirm="validate()" />
    <customerInfoModal
      ref="customerFormInfo"
      @onConfirm="handleAssignVoucher"/>
    <previewPrintVoucher ref="previewPrintVoucher" />
    <e-voucher-preview-modal ref="voucherPreviewModal" />
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { FOR_CONASI_TARGET } from 'utils/constants'
import ToastType from 'enum/toastType'
import functionUtils from 'utils/functionUtils'
import validationRules from 'utils/validationRules'
import EntityType from 'enum/entityType'
import stringUtils from 'utils/stringUtils'
import dateUtils from 'utils/dateUtils'
import AssignFromType from 'enum/assignFromType'
import CreateVoucherType from 'enum/createVoucherType'
import VoucherValue from 'enum/voucherValue'
import EmailTemplateCatelogType from 'enum/emailTemplateCatelogType'
import ConfirmModal from 'Components/ConfirmModal'
import VoucherPrintType from 'enum/voucherPrintType'
import ConfirmAssignVoucherModal from './ConfirmAssignVoucherModal'
import CustomerInfoModal from './CustomerFormInfoModal'
import VoucherTransactionValue from 'enum/voucherTransactionValue'
import PreviewPrintVoucher from 'Components/PreviewPrintVoucherModal'
import EVoucherPreviewModal from 'Views/EVoucher/EVoucherPreviewModal'
import VoucherStatusType from 'enum/voucherStatusType'
import moment from 'moment'
export default {
  components: {
    ConfirmModal,
    ConfirmAssignVoucherModal,
    CustomerInfoModal,
    PreviewPrintVoucher,
    EVoucherPreviewModal
  },
  data () {
    return {
      valid: false,
      isLoading: false,
      isShowModal: false,
      workpieceList: {
        data: [],
        id: null
      },
      quantityAssign: 0,
      distributer: {
        id: null,
        data: []
      },
      supplier: {
        id: null,
        data: []
      },
      agent: {
        id: null,
        data: []
      },
      quantityVoucherAvailableOfEntity: 0,
      voucherSelected: [],
      isAssignForCustomer: true,
      customerEmail: null,
      discount: null,
      objectSettingKey: stringUtils.ObjectSettingKey,
      price: 0,
      priceFormat: 0,
      totalPriceFormat: 0,
      // Price after discount for all voucher
      priceAfterDiscount: 0,
      priceAfterDiscountFormat: 0,
      // Price discount for all voucher
      priceDiscount: 0,
      priceDiscountFormat: 0,
      // Price after discount for only one voucher
      priceAfterDiscountOnlyVoucher: 0,
      priceAfterDiscountFormatOnlyVoucher: 0,
      // Price discount for only one voucher
      priceDiscountOnlyVoucher: 0,
      priceDiscountFormatOnlyVoucher: 0,
      totalPriceDiscount: 0,
      infoDiscountFromParent: {
        discount: 0,
        priceAfterDiscount: 0,
        priceAfterDiscountFormat: 0,
        priceDiscount: 0,
        priceDiscountFormat: 0,
        totalPriceDiscount: 0,
        totalPriceDiscountFormat: 0
      },
      discounts: [],
      isBonusWithDiscount: true,
      isExcelMode: false,
      excelListVoucherIssue: {
        results: [],
        headers: []
      },
      MODAL_WIDTH: '800px',
      isAssignFromRequestSent: false,
      voucherRequestId: null,
      issuanceId: null,
      issuanceList: [],
      // eslint-disable-next-line no-unneeded-ternary
      forConasi: FOR_CONASI_TARGET,
      voucherValueType: 1,
      VoucherValue: VoucherValue,
      confirmModalTitle: '',
      voucherIds: [],
      discountType: null,
      VoucherTransactionValue: VoucherTransactionValue,
      descriptionIssuance: '',
      conditionsOfUseIssuance: '',
      printTemplateId: null,
      deliveryNote: null,
      //
      emailTemplateList: [],
      emailTemplateId: null,
      //
      voucherExpiredDate: moment().endOf('year').format('YYYY-MM-DD'),
      menuIssueExpiredDate: false,
      voucherExpiredDateFormatted: moment().endOf('year').format('DD/MM/YYYY')
    }
  },
  computed: {
    ...mapGetters([
      'GET_CURRENT_ACCOUNT',
      'GET_SNACK_BAR',
      'QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY_DATA',
      'OBJECT_SETTING_DATA',
      'ISSUANCE_OF_VOUCHER_ALREADY_EXIST_DATA',
      'GET_ISSUANCE_LIST_DATA',
      'CHILDREN_ENTITY_DATA',
      'TEMPLATE_EMAIL_DATA'
    ])
  },
  watch: {
    TEMPLATE_EMAIL_DATA () {
      let templateEmailData = this.TEMPLATE_EMAIL_DATA.results
      if (templateEmailData.length > 0) {
        this.emailTemplateList = [
          {
            value: null,
            text: '-- ' + this.$t('templateEmail.usedDefault') + ' --',
            usedDefault: false
          }
        ]
        for (let i = 0, size = templateEmailData.length; i < size; i++) {
          let obj = {
            value: templateEmailData[i].id,
            text: templateEmailData[i].name,
            usedDefault: templateEmailData[i].used_default
          }
          this.emailTemplateList.push(obj)
        }
      } else {
        this.emailTemplateList = []
      }
    },
    CHILDREN_ENTITY_DATA () {
      let data = this.CHILDREN_ENTITY_DATA
      for (let i = 0, size = data.length; i < size; i++) {
        let entityObj = {
          value: data[i].id,
          text: data[i].name,
          imageUrl: data[i].image_url
        }
        if (data[i].type === EntityType.SUPPLIER) {
          this.supplier.data.push(entityObj)
        } else if (data[i].type === EntityType.AGENT) {
          this.distributer.data.push(entityObj)
          this.agent.data.push(entityObj)
        }
      }
    },
    QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY_DATA () {
      let data = this.QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY_DATA
      this.quantityVoucherAvailableOfEntity = data.quantity_voucher_available
        ? data.quantity_voucher_available
        : 0
      // if (!this.isAssignFromRequestSent) {
      //   this.quantityAssign = this.quantityVoucherAvailableOfEntity > 0 ? this.quantityVoucherAvailableOfEntity : null
      // }
      this.price = data.unit_price
      this.priceFormat = functionUtils.convertFormatNumberToMoney(this.price)
      this.discounts = data.discounts
      this.infoDiscountFromParent.discount = this.discounts.length !== 0 ? this.discounts[0].discount_value : 0
      this.infoDiscountFromParent.priceDiscount = this.discounts.length !== 0 ? this.discounts[0].discount_value : 0
      this.discountType = this.discounts.length !== 0 ? this.discounts[0].discount_type : null
      for (let i = 0, size = this.discounts.length; i < size; i++) {
        if (this.discounts[0].discount_value !== this.discounts[i].discount_value || this.discounts[0].discount_type !== this.discounts[i].discount_type) {
          this.infoDiscountFromParent.discount = 0
          this.infoDiscountFromParent.priceDiscount = 0
          break
        }
      }
      this.calculatorTotalPrice(this.price, this.quantityAssign)
      // this.calculatorPriceDiscountFromParent()
      this.calculatorPriceDiscountFromParentV2()
      // this.calculatorPriceDiscount(this.price)
      this.onChangeDiscountV2()
    },
    OBJECT_SETTING_DATA () {
      let data = this.OBJECT_SETTING_DATA
      let discountSettingData = data.filter((element) => {
        return element.key === this.objectSettingKey.DISCOUNT
      })
      this.discount =
        discountSettingData.length > 0 ? discountSettingData[0].value : null
      // this.calculatorPriceDiscount(this.price)
      this.onChangeDiscountV2()
    },
    ISSUANCE_OF_VOUCHER_ALREADY_EXIST_DATA () {
      let data = this.ISSUANCE_OF_VOUCHER_ALREADY_EXIST_DATA
      this.workpieceList.data = []
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          value: data[i].id,
          text: data[i].name,
          type: data[i].type,
          isPersistentCode: data[i].is_persistent_code,
          isHidePrice: data[i].is_hide_price,
          description: data[i].description,
          conditionsOfUse: data[i].conditions_of_use,
          details: data[i].details,
          expiredDate: data[i].end_date,
          supplierImage: data[i].owner.image_url,
          supplierName: data[i].owner.name,
          supplierHotline: data[i].owner.phone,
          supplierWebsite: data[i].owner.website,
          supplierId: data[i].owner.id,
          code: data[i].code,
          price: data[i].value,
          status: data[i].status,
          issuanceImage: data[i].image_url,
          usageSites: data[i].usage_sites,
          usageDuration: data[i].voucher_usage_duration,
          usageEndDate: !functionUtils.isEmptyString(data[i].voucher_usage_end_date)
            ? moment(data[i].voucher_usage_end_date).format('YYYY-MM-DD') : null
        }
        this.workpieceList.data.push(obj)
      }
      // Set issuance type value
      let workpieceSelected = this.workpieceList.data.filter(element => {
        return element.value === this.workpieceList.id
      })
      this.voucherValueType = workpieceSelected.length > 0 ? workpieceSelected[0].type : 1
    },
    GET_ISSUANCE_LIST_DATA () {
      let data = this.GET_ISSUANCE_LIST_DATA
      this.issuanceList = []
      this.issuanceId = null
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          value: data[i].id,
          prefix: data[i].prefix,
          suffix: data[i].suffix,
          distributeEndDate: data[i].distribute_end_date
        }
        this.issuanceList.push(obj)
      }
    }
  },
  created () {
    if (this.forConasi && this.isSupplier()) {
      this.isAssignForCustomer = false
    }
  },
  methods: {
    /**
     * Calculator price discount from parent v2
     */
    calculatorPriceDiscountFromParentV2: function () {
      if (this.discountType === 1) {
        this.infoDiscountFromParent.priceDiscount = functionUtils.formatInteger(this.infoDiscountFromParent.priceDiscount)
        this.infoDiscountFromParent.totalPriceDiscount = this.infoDiscountFromParent.priceDiscount * this.quantityAssign
        // Format
        this.infoDiscountFromParent.totalPriceDiscount = functionUtils.convertFormatNumberToMoney(this.infoDiscountFromParent.totalPriceDiscount)
        this.infoDiscountFromParent.priceDiscount = functionUtils.convertFormatNumberToMoney(this.infoDiscountFromParent.priceDiscount)
      } else {
        this.infoDiscountFromParent.totalPriceDiscount = (this.price * (this.infoDiscountFromParent.discount / 100)) * this.quantityAssign
        this.infoDiscountFromParent.totalPriceDiscount = functionUtils.convertFormatNumberToMoney(this.infoDiscountFromParent.totalPriceDiscount)
      }
    },
    /**
     * Get children entity
     */
    getChildrenEntity: function () {
      let filter = {
        params: {
          excludeEntityInactive: true
        }
      }
      this.isLoading = true
      this.GET_CHILDREN_ENTITY(filter)
        .then(
          function () {
            this.isLoading = false
          }.bind(this)
        )
        .catch(
          function () {
            this.isLoading = false
          }.bind(this)
        )
    },
    /**
     * Get prefix suffix
     */
    getIssuanceList: function (workpieceId) {
      let filter = {
        params: {
          workpieceId: workpieceId
        }
      }
      this.GET_ISSUANCE_LIST(filter)
    },
    /**
     * Create more voucher
     */
    onCreateMoreVoucher: function (event) {
      event.preventDefault()
      this.$emit('onCreateEVoucher', CreateVoucherType.LIST, this.workpieceList.id, this.issuanceId)
      this.setDefaultData()
    },
    /**
     * Prefix suffix change
     */
    onChangeIssuance: function () {
      this.getQuantityEVoucherAvailableOfEntity(this.workpieceList.id)
      let selectedIssuance = this.issuanceList.filter(element => {
        return element.value === this.issuanceId
      })
      if (selectedIssuance.length > 0) {
        if (!functionUtils.isEmptyString(selectedIssuance[0].distributeEndDate)) {
          let distributeEndDateData = moment(selectedIssuance[0].distributeEndDate)
          this.voucherExpiredDateFormatted = distributeEndDateData.format('YYYY-MM-DD')
          this.voucherExpiredDate = distributeEndDateData.format('DD/MM/YYYY')
          this.maxVoucherExpiredDate = distributeEndDateData.format('YYYY-MM-DD')
        }
      }
    },
    /**
     * Change price discount
     * From price to discount
     */
    onChangePriceDiscount: function () {
      let quantity = 0
      // eslint-disable-next-line eqeqeq
      if (this.voucherSelected.length != 0) {
        quantity = this.voucherSelected.length
      } else {
        quantity = this.quantityAssign
      }
      // Price discount
      let priceDiscount = 0
      priceDiscount = functionUtils.formatInteger(this.priceDiscountFormat)
      // Price discount only voucher
      let priceDiscountOnlyVoucher = 0
      priceDiscountOnlyVoucher = functionUtils.formatInteger(this.priceDiscountFormatOnlyVoucher)
      // For input all voucher
      this.discount = (priceDiscount / (this.price * quantity)) * 100
      this.discount = this.discount.toFixed(2)
      // For input only voucher
      this.discount = (priceDiscountOnlyVoucher / this.price) * 100
      this.discount = this.discount.toFixed(2)
      // For all voucher
      this.priceAfterDiscountFormat = functionUtils.convertFormatNumberToMoney(
        this.price * quantity - priceDiscount
      )
      // For only one voucher
      this.priceAfterDiscountFormatOnlyVoucher = functionUtils.convertFormatNumberToMoney(
        this.price - priceDiscountOnlyVoucher
      )
      // Calculator
      // For all voucher
      this.priceDiscountFormat = functionUtils.convertFormatNumberToMoney(
        priceDiscount
      )
      // For only voucher
      this.priceDiscountFormatOnlyVoucher = functionUtils.convertFormatNumberToMoney(
        priceDiscountOnlyVoucher
      )
    },
    /**
     * Calculator price discount v2
     */
    calculatorPriceWithPercentDiscount: function () {
      let quantity = 0
      // eslint-disable-next-line eqeqeq
      if (this.voucherSelected.length != 0) {
        quantity = this.voucherSelected.length
      } else {
        quantity = this.quantityAssign
      }
      let priceDiscount = this.price * (this.discount / 100)
      this.totalPriceDiscount = functionUtils.convertFormatNumberToMoney(
        priceDiscount * quantity
      )
    },
    /**
     * Change discount v2
     */
    onChangeDiscountV2: function () {
      let quantity = 0
      // eslint-disable-next-line eqeqeq
      if (this.voucherSelected.length != 0) {
        quantity = this.voucherSelected.length
      } else {
        quantity = this.quantityAssign
      }
      if (this.isBonusWithDiscount) {
        this.calculatorPriceWithPercentDiscount()
      } else {
        let priceDiscount = 0
        priceDiscount = functionUtils.formatInteger(this.priceDiscountFormat)
        this.priceDiscountFormat = functionUtils.convertFormatNumberToMoney(
          priceDiscount
        )
        this.totalPriceDiscount = functionUtils.convertFormatNumberToMoney(
          priceDiscount * quantity
        )
        this.priceDiscount = priceDiscount
      }
    },
    /**
     * Validate discounts
     */
    validateDiscount () {
      for (let i = 0, size = this.discounts.length; i < size; i++) {
        if (this.discounts[0].discount_value !== this.discounts[i].discount_value || this.discounts[0].discount_type !== this.discounts[i].discount_type) {
          return 'Không thể phát hành những voucher có chiết khấu khác nhau cùng lúc'
        }
      }
      return true
    },
    /**
     * Calculator price discount from parent
     */
    calculatorPriceDiscountFromParent: function () {
      let priceWithQuantity = this.price * this.quantityAssign
      this.infoDiscountFromParent.priceAfterDiscount =
        priceWithQuantity - ((priceWithQuantity * this.infoDiscountFromParent.discount) / 100).toFixed(2)
      this.infoDiscountFromParent.priceDiscount =
        priceWithQuantity - this.infoDiscountFromParent.priceAfterDiscount
      // Format
      this.infoDiscountFromParent.priceAfterDiscountFormat = functionUtils.convertFormatNumberToMoney(
        this.infoDiscountFromParent.priceAfterDiscount
      )
      this.infoDiscountFromParent.priceDiscountFormat = functionUtils.convertFormatNumberToMoney(
        this.infoDiscountFromParent.priceDiscount
      )
    },
    /**
     * Quantity assign change
     */
    onQuantityAssignChange: function () {
      // this.calculatorPriceDiscount(this.price)
      this.onChangeDiscountV2()
      // this.calculatorPriceDiscountFromParent()
      this.calculatorPriceDiscountFromParentV2()
      this.calculatorTotalPrice(this.price, this.quantityAssign)
    },
    /**
     * Change discount
     */
    onChangeDiscount: function () {
      this.calculatorPriceDiscount(this.price)
    },
    /**
     * Check is supplier
     */
    isSupplier: function () {
      return functionUtils.isEntityType(EntityType.SUPPLIER)
    },
    concatSuffixPrefix: function (suffixPrefix) {
      return functionUtils.concatSuffixPrefix(suffixPrefix)
    },
    // Required value
    ruleRequiredValue (value) {
      return validationRules.ruleRequiredValue(value)
    },
    // Not greater than remain e voucher
    ruleMustLessThanAvailableEVoucher (value) {
      return validationRules.ruleMustLessThanOrEqual(value, this.quantityVoucherAvailableOfEntity)
    },
    // Not greater one hundred
    ruleMustLessThanOneHundred (value) {
      return validationRules.ruleMustLessThan(value, 100)
    },
    ruleLengthMustLessThan (text) {
      return validationRules.ruleLengthMustLessThan(text, 150)
    },
    // Greater than zero
    ruleMustGreaterThanZero (value) {
      return validationRules.ruleMustGreaterThan(value, 0)
    },
    ruleMustNotLessThanZero (value) {
      return validationRules.ruleMustGreaterThanOrEqual(value, 0)
    },
    ruleValidPhoneOrEmail (value) {
      return validationRules.ruleValidPhoneOrEmail(value)
    },
    /**
     * Not greater unit price
     */
    ruleNotGreaterThanUnitPrice () {
      if (this.priceDiscount <= this.price) {
        return true
      }
      return this.$t('assignVoucher.discountedFaceValueMustNotGreaterActualValue')
    },
    /**
     * Check is agent
     */
    isAgent: function () {
      return functionUtils.isEntityType(EntityType.AGENT)
    },
    /**
     * Get e voucher issuance list by entity
     */
    getEVoucherIssuanceListByEntity: function () {
      this.quantityAssign = 0
      this.quantityVoucherAvailableOfEntity = 0
      this.workpieceList.id = null
      let filter = {
        params: {
          isAssignForCustomer: this.isAssignForCustomer
        }
      }
      this.GET_ISSUANCE_OF_VOUCHER_ALREADY_EXIST_LIST(filter)
    },
    /**
     * Get quantity e voucher available of entity
     */
    getQuantityEVoucherAvailableOfEntity: function (workpieceId) {
      let entityType = sessionStorage.getItem('entityType')
      let transactionType = (!this.isAssignForCustomer || entityType === EntityType.SUPPLIER) ? EntityType.AGENT : EntityType.CUSTOMER
      let filter = {}
      if (!functionUtils.isNull(workpieceId)) {
        filter = {
          params: {
            workpieceId: workpieceId,
            transactionType: transactionType,
            issuanceId: this.issuanceId
          }
        }
      } else {
        filter = {
          params: {
            transactionType: transactionType,
            issuanceId: this.issuanceId
          }
        }
      }
      this.GET_QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY(filter)
    },
    getTemplateEmailList: function () {
      if (!this.isSupplier()) {
        this.emailTemplateList = []
      }
      let filter = {
        params: {
          categoryId: EmailTemplateCatelogType.RELEASE_TO_CUSTOMER
        }
      }
      this.emailTemplateId = null
      this.isLoading = true
      this.GET_TEMPLATE_EMAIL(filter).then(
        function () {
          this.isLoading = false
        }.bind(this)
      ).catch(
        function () {
          this.isLoading = false
        }.bind(this)
      )
    },
    /**
     * Change e voucher issuance
     */
    onWorkpieceChange: function (workpieceId) {
      let workpieceSelected = this.workpieceList.data.filter(element => {
        return element.value === workpieceId
      })
      if (workpieceSelected.length > 0) {
        this.voucherValueType = workpieceSelected[0].type
        this.descriptionIssuance = workpieceSelected[0].description
        this.conditionsOfUseIssuance = workpieceSelected[0].conditionsOfUse
        let usageDuration = workpieceSelected[0].usageDuration
        let usageEndDate = workpieceSelected[0].usageEndDate
        if (usageEndDate) {
          this.voucherExpiredDateFormatted = moment(usageEndDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
          this.voucherExpiredDate = usageEndDate
          this.maxVoucherExpiredDate = usageEndDate
        } else if (usageDuration) {
          this.voucherExpiredDateFormatted = moment().add(usageDuration, 'days').format('DD/MM/YYYY')
          this.voucherExpiredDate = moment().add(usageDuration, 'days').format('YYYY-MM-DD')
          this.maxVoucherExpiredDate = moment().add(usageDuration, 'days').format('YYYY-MM-DD')
        }
      } else {
        this.voucherValueType = 1
        this.descriptionIssuance = ''
        this.conditionsOfUseIssuance = 1
      }
      this.discounts = []
      this.getQuantityEVoucherAvailableOfEntity(workpieceId)
      this.getIssuanceList(workpieceId)
      this.quantityAssign = 0
    },
    /**
     * Preview print voucher
     */
    onPreviewPrintVoucher: function () {
      let workpieceSelected = this.workpieceList.data.filter(element => {
        return element.value === this.workpieceList.id
      })
      let agentSelected = this.agent.data.filter(element => {
        return element.value === this.agent.id
      })
      let distributerSelected = this.distributer.data.filter(element => {
        return element.value === this.distributer.id
      })
      let entityType = sessionStorage.getItem('entityType')
      let agentImage = null
      if (entityType === EntityType.SUPPLIER) {
        if (agentSelected.length > 0) {
          agentImage = agentSelected[0].imageUrl
        } else if (distributerSelected.length > 0) {
          agentImage = distributerSelected[0].imageUrl
        }
      } else if (entityType === EntityType.AGENT) {
        let parentImage = sessionStorage.getItem('entityParentImage')
        // agentImage = sessionStorage.getItem('entityImage')
        agentImage = parentImage
      }

      let voucherInfo = {
        supplierId: workpieceSelected.length > 0 ? workpieceSelected[0].supplierId : null,
        supplierName: workpieceSelected.length > 0 ? workpieceSelected[0].supplierName : '',
        supplierHotline: workpieceSelected.length > 0 ? workpieceSelected[0].supplierHotline : '',
        supplierWebsite: workpieceSelected.length > 0 ? workpieceSelected[0].supplierWebsite : '',
        name: workpieceSelected.length > 0 ? workpieceSelected[0].text : null,
        price: this.price,
        isHidePrice: workpieceSelected.length > 0 ? workpieceSelected[0].isHidePrice : false,
        type: this.voucherValueType,
        expiredDate: workpieceSelected.length > 0 ? workpieceSelected[0].expiredDate : null,
        description: workpieceSelected.length > 0 ? workpieceSelected[0].description : '',
        conditionsOfUse: workpieceSelected.length > 0 ? workpieceSelected[0].conditionsOfUse : '',
        details: workpieceSelected.length > 0 ? workpieceSelected[0].details : null,
        logoSupplier: workpieceSelected.length > 0 ? workpieceSelected[0].supplierImage : null,
        logoAgent: agentImage
      }

      this.$refs.previewPrintVoucher.onShowModal(voucherInfo, this.printTemplateId)
    },
    /**
     * Check null
     */
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
     * Set default data
     */
    setDefaultData: function () {
      this.isShowModal = false
      this.quantityAssign = 0
      this.quantityVoucherAvailableOfEntity = 0
      this.distributer.id = null
      this.workpieceList.id = null
      this.agent.id = null
      this.discount = null
      this.price = 0
      this.priceFormat = 0
      this.totalPriceFormat = 0
      this.priceAfterDiscount = 0
      this.priceAfterDiscountFormat = 0
      this.priceDiscount = 0
      this.priceDiscountFormat = 0
      this.infoDiscountFromParent.discount = 0
      this.infoDiscountFromParent.priceAfterDiscount = 0
      this.infoDiscountFromParent.priceAfterDiscountFormat = 0
      this.infoDiscountFromParent.priceDiscount = 0
      this.infoDiscountFromParent.priceDiscountFormat = 0
      this.infoDiscountFromParent.totalPriceDiscount = 0
      this.infoDiscountFromParent.totalPriceDiscountFormat = 0
      this.MODAL_WIDTH = '800px'
      this.isExcelMode = false
      this.excelListVoucherIssue.results = []
      this.excelListVoucherIssue.headers = []
      this.issuanceId = null
      this.customerEmail = null
      this.deliveryNote = null
    },
    /**
     * Calculator price discount
     */
    calculatorPriceDiscount: function (price) {
      let quantity = 0
      if (this.voucherSelected.length > 0) {
        quantity = this.voucherSelected.length
      } else {
        quantity = this.quantityAssign
      }
      let priceWithQuantity = price * quantity
      this.priceAfterDiscount =
        priceWithQuantity - ((priceWithQuantity * this.discount) / 100).toFixed(2)
      // Price after discout format for all voucher
      this.priceAfterDiscountFormat = functionUtils.convertFormatNumberToMoney(
        this.priceAfterDiscount
      )
      // Price discount for all voucher
      this.priceDiscount = priceWithQuantity - this.priceAfterDiscount
      this.priceDiscountFormat = functionUtils.convertFormatNumberToMoney(
        this.priceDiscount
      )
      // Price after discount format for only voucher
      this.priceAfterDiscountOnlyVoucher = price - ((price * this.discount) / 100).toFixed(2)
      this.priceAfterDiscountFormatOnlyVoucher = functionUtils.convertFormatNumberToMoney(
        this.priceAfterDiscountOnlyVoucher
      )
      // Price discount for only voucher
      this.priceDiscountOnlyVoucher = price - this.priceAfterDiscountOnlyVoucher
      this.priceDiscountFormatOnlyVoucher = functionUtils.convertFormatNumberToMoney(
        this.priceDiscountOnlyVoucher
      )
    },
    /**
     * Calculator total price
     */
    calculatorTotalPrice: function (price, quantity) {
      // Total price format
      this.totalPriceFormat = functionUtils.convertFormatNumberToMoney(price * quantity)
    },
    /**
     * Show modal
     */
    onShowModal: function (
      voucherSelected,
      workpieceId,
      assignFromType,
      assignFromRequestSentData,
      issuanceId
    ) {
      this.getEVoucherIssuanceListByEntity()
      this.getChildrenEntity()
      this.getTemplateEmailList()
      let entityId = sessionStorage.getItem('entityId')
      let filter = {
        params: {
          objectId: entityId,
          objectType: 'Entity'
        }
      }
      this.GET_OBJECT_SETTING(filter)
      this.voucherSelected = voucherSelected
      this.printTemplateId = null
      if (this.voucherSelected.length > 0) {
        this.printTemplateId = this.voucherSelected[0].printTemplateId
        this.price = this.voucherSelected[0].price
        this.priceFormat = functionUtils.convertFormatNumberToMoney(this.price)
        this.calculatorTotalPrice(this.price, this.voucherSelected.length)
        // this.calculatorPriceDiscount(this.price)
        this.onChangeDiscountV2()
        let totalPrice = 0
        this.infoDiscountFromParent.priceDiscount = 0
        this.infoDiscountFromParent.totalPriceDiscount = 0
        for (let i = 0, size = this.voucherSelected.length; i < size; i++) {
          this.discountType = this.voucherSelected[i].discountType

          totalPrice += this.voucherSelected[i].price
          this.infoDiscountFromParent.discount = this.voucherSelected[0].discountValue
          if (this.discountType === VoucherTransactionValue.PRICE) {
            this.infoDiscountFromParent.totalPriceDiscount += this.voucherSelected[i].discountValue
            this.infoDiscountFromParent.priceDiscount = this.voucherSelected[i].discountValue
          } else {
            this.infoDiscountFromParent.totalPriceDiscount += this.voucherSelected[i].priceDiscount
          }
        }
        this.infoDiscountFromParent.priceAfterDiscount =
          totalPrice - this.infoDiscountFromParent.priceDiscount
        // Format
        this.infoDiscountFromParent.totalPriceDiscount = functionUtils.convertFormatNumberToMoney(
          this.infoDiscountFromParent.totalPriceDiscount
        )
        this.infoDiscountFromParent.priceAfterDiscount = functionUtils.convertFormatNumberToMoney(
          this.infoDiscountFromParent.priceAfterDiscount
        )
        this.infoDiscountFromParent.priceDiscount = functionUtils.convertFormatNumberToMoney(
          this.infoDiscountFromParent.priceDiscount
        )
        this.workpieceList.id = this.voucherSelected[0].workpiece && this.voucherSelected[0].workpiece.id
      } else {
        this.price = 0
        this.priceFormat = 0
      }
      if (issuanceId) {
        this.issuanceId = issuanceId
      }
      if (workpieceId) {
        this.workpieceList.id = workpieceId
        this.getQuantityEVoucherAvailableOfEntity(workpieceId)
        this.getIssuanceList(workpieceId)
      } else {
        this.issuanceList = []
      }
      this.isAssignFromRequestSent = false
      if (assignFromType === AssignFromType.FROM_REQUEST_SENT) {
        this.isAssignFromRequestSent = true
        setTimeout(() => {
          this.quantityAssign = assignFromRequestSentData.quantity
        }, 1000)
        this.distributer.id = assignFromRequestSentData.fromEntity.id
        this.agent.id = assignFromRequestSentData.fromEntity.id
        this.isAssignForCustomer = false
        this.voucherRequestId = assignFromRequestSentData.requestId
      }
      this.isShowModal = true
    },
    /**
     * Close modal
     */
    onCloseModal: function () {
      this.setDefaultData()
    },
    /**
     * Handle assign voucher
     */
    handleAssignVoucher: function (customerInfo) {
      let voucherExpiredDate = dateUtils.formatSqlDate(this.voucherExpiredDateFormatted)
      let filter = {
        quantity: this.quantityAssign,
        voucherSelected: this.voucherSelected,
        toEntity: this.distributer.id,
        discount: !functionUtils.isNull(this.discount) && this.discount !== '' ? this.discount : null,
        isAssignFromRequestSent: this.isAssignFromRequestSent,
        voucherRequestId: this.voucherRequestId,
        issuanceId: this.issuanceId,
        email: this.customerEmail,
        valueType: this.voucherValueType,
        customerUrl: stringUtils.CUSTOMER_URL,
        discountType: this.isBonusWithDiscount ? VoucherTransactionValue.PERCENT : VoucherTransactionValue.PRICE,
        price: this.priceDiscount,
        voucherExpiredDate: this.forConasi && !this.isAssignForCustomer ? voucherExpiredDate : null,
        emailTemplateId: this.emailTemplateId,
        deliveryNote: this.deliveryNote
      }
      if (customerInfo) {
        let firstLastName = functionUtils.getFirstLastName(customerInfo.fullName)
        customerInfo['lastName'] = firstLastName.lastName
        customerInfo['firstName'] = firstLastName.firstName
      } else {
        customerInfo = {
          id: null,
          phone: null,
          gender: null,
          lastName: null,
          firstName: null,
          birthDateFormatted: null
        }
      }
      filter['customerInfo'] = customerInfo
      if (this.isSupplier()) {
        // eslint-disable-next-line eqeqeq
        if (this.voucherSelected.length == 0) {
          filter['quantityAssign'] = this.quantityAssign
          filter['workpieceId'] = this.workpieceList.id
        }
        // eslint-disable-next-line eqeqeq
        filter['type'] = !this.isAssignForCustomer || FOR_CONASI_TARGET ? EntityType.SUPPLIER : EntityType.CUSTOMER
      } else if (this.isAgent()) {
        if (this.voucherSelected.length === 0) {
          filter['quantityAssign'] = this.quantityAssign
          filter['workpieceId'] = this.workpieceList.id
          filter['toEntity'] = !this.isAssignForCustomer ? this.agent.id : null
          filter['type'] = !this.isAssignForCustomer ? EntityType.AGENT : EntityType.CUSTOMER
        } else {
          filter['toEntity'] = !this.isAssignForCustomer ? this.agent.id : null
          filter['type'] = !this.isAssignForCustomer ? EntityType.AGENT : EntityType.CUSTOMER
        }
      }
      this.isLoading = true
      this.TRANSACTION_VOUCHER(filter)
        .then(
          function (res) {
            this.isLoading = false
            this.setDefaultData()
            this.$emit('transactionVoucherSuccess')
            if (this.isAssignForCustomer && !this.isSupplier() && this.forConasi) {
              let data = res.data
              this.voucherIds = data.data
              this.showModalConfirmInVoucher()
            }
            this.ON_SHOW_TOAST({
              message: this.$t('common.success'),
              styleType: ToastType.SUCCESS
            })
          }.bind(this)
        )
        .catch(
          function (error) {
            this.isLoading = false
            if (!functionUtils.isNull(error.response.data.errorText)) {
              let errorText = error.response.data.errorText
              this.ON_SHOW_TOAST({
                message: this.$t(`${errorText}`),
                styleType: ToastType.ERROR
              })
            } else {
              this.ON_SHOW_TOAST({
                message: this.$t('login.somethingWentWrong'),
                styleType: ToastType.ERROR
              })
            }
          }.bind(this)
        )
    },
    validate: function () {
      if (this.$refs.form.validate()) {
        if (this.isAssignForCustomer) {
          this.$refs.customerFormInfo.onShowModal(this.customerEmail)
        } else {
          this.handleAssignVoucher()
        }
      }
    },
    /**
     * Show modal confirm in voucher
     */
    showModalConfirmInVoucher: function () {
      this.confirmModalTitle = this.$t('printVoucher.wantToPrintRecently')
      this.$refs.confirmModal.onShowModal()
    },
    /**
     * Confirm print e voucher
     */
    confirmPrintEVoucher: function () {
      let routeData = this.$router.resolve({ path: 'print-evoucher-v2', query: { voucherIds: JSON.stringify(this.voucherIds), type: VoucherPrintType.SELECTED } })
      window.open(routeData.href, '_blank')
    },
    /**
     * Confirm
     */
    onConfirm: function () {
      if (this.$refs.form.validate()) {
        // let title = 'Bạn có chắc chắn muốn phát hành voucher này?'
        let receiver = ''
        if (this.isAssignForCustomer) {
          receiver = this.customerEmail
        } else if (this.isSupplier()) {
          let distributer = this.distributer.data.filter((item) => {
          // eslint-disable-next-line eqeqeq
            return item.value == this.distributer.id
          })
          receiver = distributer.length > 0 ? distributer[0].text : ''
        } else if (this.isAgent()) {
          let agent = this.agent.data.filter((item) => {
          // eslint-disable-next-line eqeqeq
            return item.value == this.agent.id
          })
          receiver = agent.length > 0 ? agent[0].text : ''
        }
        let data = {
        // title: title,
          quantity: this.voucherSelected.length > 0 ? this.voucherSelected.length : this.quantityAssign,
          receiver: receiver,
          isAssignForCustomer: this.isAssignForCustomer
        }
        this.$refs.confirmAssignVoucherModal.onShowModal(data)
      }
    },
    /**
     * Assign for customer change
     */
    assignForCustomerChange: function () {
      if (!this.isAssignForCustomer) {
        this.isExcelMode = false
      }
      this.getEVoucherIssuanceListByEntity()
      if (this.voucherSelected.length === 0) {
        this.getQuantityEVoucherAvailableOfEntity(this.workpieceList.id)
      }
    },
    /**
     * Check has action
     */
    hasAction: function (metaAction) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaAction)
    },
    getRandomCode: function (length) {
      return functionUtils.randomString(length)
    },
    onPreviewVoucherInfo: function () {
      let workpieceSelected = this.workpieceList.data.filter(element => {
        return element.value === this.workpieceList.id
      })
      if (workpieceSelected.length > 0) {
        let voucherInfo = {
          id: workpieceSelected[0].value,
          code: workpieceSelected[0].code,
          type: workpieceSelected[0].type,
          value: workpieceSelected[0].price,
          name: workpieceSelected[0].text,
          description: workpieceSelected[0].description,
          conditionsOfUse: workpieceSelected[0].conditionsOfUse,
          details: workpieceSelected[0].details,
          imageUrl: workpieceSelected[0].issuanceImage,
          supplierName: workpieceSelected[0].supplierName,
          // status: workpieceSelected[0].status,
          status: VoucherStatusType.ISSUING,
          expiredDate: workpieceSelected[0].expiredDate,
          // usedPrice: null,
          // usedDiscountPrice: null,
          workpieceCode: null,
          usageSites: workpieceSelected[0].usageSites,
          discountType: workpieceSelected[0].type
        }
        this.$refs.voucherPreviewModal.onShowModal(null, voucherInfo)
      } else {
        this.ON_SHOW_TOAST({
          message: this.forConasi ? this.$t('assignVoucher.selectTheWorkPieceCode') : this.$t('assignVoucher.selectVoucher'),
          styleType: ToastType.ERROR
        })
      }
    },
    ...mapActions([
      'ON_SHOW_TOAST',
      'TRANSACTION_VOUCHER',
      'GET_QUANTITY_VOUCHER_AVAILABLE_OF_ENTITY',
      'GET_OBJECT_SETTING',
      'GET_ISSUANCE_OF_VOUCHER_ALREADY_EXIST_LIST',
      'GET_ISSUANCE_LIST',
      'GET_CHILDREN_ENTITY',
      'GET_TEMPLATE_EMAIL'
    ])
  }
}
</script>
<style lang="scss" scoped>
.modal {
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.v-card {
  margin-bottom: 0px !important;
  margin-top: 21px !important;
}
/deep/.required label::after {
  content: " *";
  color: red;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 5px;
}
</style>
